<!--
 * @Description: 解答题
 * @Author: liqin
 * @Date: 2022-02-17 17:32:34
-->
<template>
  <div>
    <div class="mt20">
      <div>
        <span style="color: #999" v-if="!paper.isShowQuestionType">简答题</span>
        {{ "\xa0\xa0\xa0" }}
        <span>总分：{{ val.score }} 分</span>
        {{ "\xa0\xa0\xa0" }}
        <span class="red">得分：{{ val.userGetScore }}</span>
      </div>
      <div class="dian mt10">{{ val.bankName }} {{ "\xa0" }}</div>
    </div>
    <div class="textarea" :class="val.userOptionIsok==1?'green':'error'">
      <el-input
        v-model="optionAnswer"
        :disabled="isView"
        class="widths"
        type="textarea"
      ></el-input>
    </div>
    <div class="mt10">正确答案：{{ val.bankAnswer }}</div>
    <div class="mt10">
      <el-button type="primary" size="mini" @click="dialogVisible = true"
        >点击查看答案解析</el-button
      >
    </div>
    <!-- 答案解析弹框 -->
    <el-dialog title="答案解析" :visible.sync="dialogVisible">
      <span v-if="val.bankAnswerParse" v-html="val.bankAnswerParse"></span>
      <span v-else style="text-align: center">无答案解析</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
    
<script>
export default {
  props: {
    val: [Object],
    isView: {
      type: Boolean,
      default: () => false,
    },
    isAnswer: {
      // 答案显示
      type: Boolean,
      default: () => false,
    },
    isScore: {
      // 得分显示
      type: Boolean,
      default: () => false,
    },
    paper: {
      //
      type: Object,
      default: () => {
        return {};
      },
    },
    userPapersId: [String],
    topicOutlineId: {
      // 得分显示
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      optionAnswer: "",
      dialogVisible: false,
    };
  },
  methods:{
    setColor(val,val2){
      let obj={}
      if(val!=val2){
        obj['color']='blue'
      }
      return obj
    }
  },
  watch: {
    val: {
      deep: true,
      immediate: true,
      handler(val) {
        val.options.forEach((item) => {
          // item.isCheck == 1 ? this.radio = item.optionSort : ''
          this.optionAnswer = item.optionAnswer;
        });
      },
    },
  },
};
</script>

<style scoped>
.widths {
  margin-top: 10px;
  width: 500px;
}
</style>
<style lang="scss" scoped>
.red {
  color: red;
}
.error ::v-deep.el-textarea.is-disabled .el-textarea__inner{
  color: #f56c6c !important;
}
.green ::v-deep.el-textarea.is-disabled .el-textarea__inner{
  color: #0b8d73 !important;
}
.textarea ::v-deep.el-textarea__inner{
  width: 60%;
}

//pc端
@media only screen and (min-width: 1200px) {
  ::v-deep .el-dialog{
    width: 40% !important;
  }
}
//pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  ::v-deep .el-dialog{
    width: 40% !important;
  }
}
// 手机端
@media only screen and (max-width: 767px) {
  ::v-deep .el-dialog{
    width: 90% !important;
  }
}
</style>